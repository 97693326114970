exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-community-js": () => import("./../../../src/pages/join-community.js" /* webpackChunkName: "component---src-pages-join-community-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/Category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

